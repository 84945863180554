import React from 'react';
import AttributesPage from '../Attributes';
import {Form, FormControl, FormGroup, FormLabel, Row} from 'react-bootstrap';
import {Button} from '@mui/material'
import {t} from '../../../utils/Translator';
import {Formik} from 'formik';
import * as yup from 'yup';
import {Command, Device, Maintenance} from '../../../models';
import {deepClone, isEqual} from '../../../utils/functions';
import device_images from '../../../config/device_images';
import CustomSelect from '../../Controls/CustomSelect';
import CustomCheckbox from '../../Controls/CustomCheckBox';
import {store} from '../../../store';
import PermissionsToolbar from '../Permissions/toolbar';
import Collapse from '../../Collapse';
import CustomNumberField from '../../Controls/CustomNumberField';
import {AppService, CommandsService, DevicesService, MaintenancesService, PermissionsService} from '../../../services';
import CustomColorField from "../../Controls/CustomColorField";
import {App} from "../../../App";

// import "react-datepicker/dist/react-datepicker.css";
import Datetime from "react-datetime";
import {Grid} from "@mui/material";

export default class DeviceForm extends React.Component {
    constructor(props) {
        super(props);

        const categoryOptions = [];
        device_images.ids.map(v => {
            categoryOptions.push({value: v, label: t('category' + v.ucFirst())});
        })

        categoryOptions.sort((a, b) => a.label > b.label ? 1 : -1);

        const groupOptions = [];
        Object.values(store.getState().groups.groups).map(v => {
            if (v.id > 0) {
                groupOptions.push({value: v.id, label: v.name});
            }

        })

        groupOptions.sort((a, b) => a.label > b.label ? 1 : -1);

        const driverOptions = [];
        Object.values(store.getState().drivers.drivers).map(d => {
            driverOptions.push({value: d.uniqueId, label: d.name});
        })
        driverOptions.sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1)

        if (props.item.attributes === undefined)
            props.item.attributes = {}

        this.state = {
            attributesPage: false,
            item: new Device().deserialize(deepClone(props.item)),
            odometer: null,
            mode: props.mode,
            sending: false,
            changed: false,
            maintenanceDateSelected: false,
            maintenancePeriodSelected: false,
            categoryOptions: categoryOptions,
            groupOptions: groupOptions,
            driverOptions: driverOptions,
            deviceMaintenance: null,
        }

        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
        this.openAttributesPage = this.openAttributesPage.bind(this);
        this.onAttributesCancel = this.onAttributesCancel.bind(this);
        this.onAttributesClosed = this.onAttributesClosed.bind(this);

        MaintenancesService.list({deviceId: this.state.item.id}, false).then(m => {
            if (m.length > 0) {
                this.setState({
                    deviceMaintenance: m[0].deserialize(),
                    maintenanceDateSelected: m[0]._data.attributes["date"] !== undefined,
                    maintenancePeriodSelected: m[0]._data.attributes["maintenancePeriod"] !== undefined
                })
            }
        });
    }

    async setOdometer(device) {
        if (this.state.odometer !== null) {
            const command = new Command()
            command.description = "New"
            command.type = "custom"
            command.attributes = {
                data: "odoset:" + (this.state.odometer / 1000)
            }
            command.deviceId = device.id
            command.id = 0
            command.textChannel = null

            const response = await CommandsService.send(command)

            if (response.status === 202) {
                App.App.showToast(t('commandQueued'), '', {
                    type: 'success',
                })
            } else {
                App.App.showToast(t('commandSent'), '', {
                    type: 'success',
                })
            }
        }
    }

    async setMaintenance(item) {
        const m = this.state.deviceMaintenance;
        if (m == null) return;

        const maintenance = Maintenance.build(
            m,
            item.name,
            this.state.maintenanceDateSelected,
            this.state.maintenancePeriodSelected,
            m.attributes['date'],
            m.attributes['repeatable'],
            m.attributes['repeatType'],
            m.attributes['repeatDayCount'],
            m.attributes['maintenanceStart'],
            m.attributes['maintenancePeriod']
        )

        if (maintenance.type == null || maintenance.name == null) {
            console.log("Maint type or name null", maintenance)
            return
        }
        try {
            const result = maintenance.id > 0 ? await MaintenancesService.update(new Maintenance().deserialize(maintenance))
            : await MaintenancesService.save(new Maintenance().deserialize(maintenance))
            const data = {
                "deviceId": item.id,
                maintenanceId: result.id,
            }
            await PermissionsService.save(data)
        } catch (e) {
            console.log(e)
        }
    }

    async save(values) {
        console.log('VALUES', values)
        const item = this.state.item.deserialize(values);
        if (this.props.onSave) {
            this.props.onSave(item);
            await this.setOdometer(item)
            await this.setMaintenance(item)
        } else {
            try {
                let device = this.state.item

                if (device.id > 0) {
                    device = await DevicesService.update(device);
                } else {
                    device = await DevicesService.save(device);
                }

                await this.setOdometer(device)
                await this.setMaintenance(device)
            } catch (ex) {
                AppService.showError(ex);
            }
        }
    }

    cancel() {
        this.props.onHide();
    }

    openAttributesPage() {
        this.setState({attributesPage: true})
    }

    async saveAccumulators(values) {
        try {
            await DevicesService.saveAcumulators({
                deviceId: this.state.item.id,
                totalDistance: values.totalDistance,
                hours: values.hours
            });
        } catch (ex) {
            AppService.showError(ex);
        }
    }

    onAttributesCancel() {
        const clone = new Device().deserialize(deepClone(this.props.item));
        const item = this.state.item;
        item.attributes = clone.attributes;
        this.setState({
            ...this.state,
            item,
            attributesPage: false
        })
    }

    onAttributesClosed() {
        this.setState({
            ...this.state,
            attributesPage: false,
            changed: !isEqual(this.state.item.attributes, this.props.item.attributes)
        })
    }

    componentWillReceiveProps(newProps, newState) {
        this.setState({item: new Device().deserialize(deepClone(newProps.item))})
    }

    render() {
        const {
            sending,
            item,
            driverOptions,
            categoryOptions,
            groupOptions,
            maintenanceDateSelected,
            maintenancePeriodSelected
        } = this.state;

        const position = store.getState().positions.positions[item.id];

        const schema = yup.object({
            name: yup.string().required(),
            uniqueId: yup.string().required(),
            category: yup.string().required(),
        });

        //maintenance
        const maintenance = this.state.deviceMaintenance;

        const maintenanceCurrentDate = maintenance !== null && maintenance.attributes['date'] ?
            new Date(maintenance.attributes['date']) : Date.now();
        const repeatableSelected = maintenance !== null ?
            maintenance.attributes["repeatable"] ?? false : false
        const currentRepeatCount = maintenance !== null ? maintenance.attributes['repeatDayCount'] ?? 0 : 0;
        const maintenanceName = maintenance !== null ? maintenance.name : null
        const maintenanceRepeatType = maintenance !== null ? maintenance.attributes['repeatType'] ?? 0 : 0;

        const maintenanceOptions = [
            {
                name: t("Days"),
                value: 0
            },
            {
                name: t("Months"),
                value: 1
            },
            {
                name: t("Year"),
                value: 2
            }
        ]

        //tech inspection
        const techInspectionCurrentDate = item.attributes['techInspectionDate'] ?
            new Date(item.attributes['techInspectionDate']) : Date.now();
        const techInspectionRepeatableSelected = item.attributes["techInspectionRepeatable"] ?? false
        const techInspectionCurrentRepeatCount = item.attributes['techInspectionRepeatDayCount'] ?? 0
        const techInspectionName = item.attributes["techInspectionName"] ?? ""
        const techInspectionRepeatType = item.attributes['techInspectionRepeatType'] ?? 0;
        //insurance
        const insuranceCurrentDate = item.attributes['insuranceDate'] ?
            new Date(item.attributes['insuranceDate']) : Date.now();
        const insuranceRepeatableSelected = item.attributes["insuranceRepeatable"] ?? false
        const insuranceCurrentRepeatCount = item.attributes['insuranceRepeatDayCount'] ?? 0
        const insuranceName = item.attributes["insuranceName"] ?? ""
        const insuranceRepeatType = item.attributes['insuranceRepeatType'] ?? 0
        const brand = item.attributes["brand"] ?? ""

        return <React.Fragment>
            {this.state.attributesPage ?
                <AttributesPage item={item} type="devicesAttributes" onHide={() => this.onAttributesClosed()}
                                onSave={() => this.onAttributesClosed()} onCancel={() => this.onAttributesCancel()}/>
                : null}


            <div className="container-wrapper">
                <Formik
                    validationSchema={schema}
                    onSubmit={this.save}
                    disabled={sending}
                    initialValues={{
                        name: item.name,
                        uniqueId: item.uniqueId,
                        category: item.category,
                        groupId: item.groupId,
                        phone: item.phone,
                        model: item.model,
                        contact: item.contact,
                        disabled: item.disabled,
                        totalDistance: position ? position.getAttribute('totalDistance') : 0.00,
                        hours: position ? position.getAttribute('hours') : 0,
                        attributes: item.attributes || {},
                        maintenanceCurrentDate,
                        repeatableSelected,
                        currentRepeatCount,
                        maintenanceName,
                        maintenanceOptions,
                        maintenanceRepeatType,
                        maintenancePeriodSelected,
                        techInspectionCurrentDate,
                        techInspectionRepeatableSelected,
                        techInspectionCurrentRepeatCount,
                        techInspectionName,
                        techInspectionRepeatType,
                        insuranceCurrentDate,
                        insuranceRepeatableSelected,
                        insuranceCurrentRepeatCount,
                        insuranceName,
                        insuranceRepeatType,
                        brand
                    }}
                >
                    {({
                          values,
                          handleSubmit,
                          handleChange,
                          dirty,
                          isSubmitting,
                          setFieldValue,
                          errors,
                          isValid
                      }) => {
                        return (

                            <Form onSubmit={handleSubmit} noValidate
                                  className="d-flex flex-column h-100 w-100 flex-grow-1 form form-sm ">
                                <React.Fragment>
                                    <div className="flex-grow-1 pt-3 overflow">
                                        <div className="container ">
                                            <h3 className="internal-title"><i
                                                className="mdi mdi-car"></i> {this.state.item.id ? t('sharedEdit') : t('sharedAdd')} {t('sharedDevice')}
                                            </h3>
                                            {this.state.item.id ? <PermissionsToolbar item={this.state.item}/> : null}

                                            <Grid className="mt-4" container rowSpacing={1}
                                                  columnSpacing={{xs: 1, sm: 2, md: 3}}>
                                                <Grid item xs={6}>
                                                    <div>
                                                        <FormGroup>
                                                            <FormLabel>{t("sharedName")}</FormLabel>
                                                            <FormControl
                                                                type="text"
                                                                name="name"
                                                                defaultValue={values.name}
                                                                onChange={handleChange}
                                                                isInvalid={!!errors.name}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <div>
                                                        <FormGroup>
                                                            <FormLabel>{t("deviceCategory")}</FormLabel>
                                                            <CustomSelect options={categoryOptions} name="category"
                                                                          isClearable
                                                                          defaultValue={values.category}
                                                                          onChange={setFieldValue}/>
                                                            {/*<div className="category-options">
                                                    {categoryOptions.map(o => {
                                                        return (
                                                            <div className={"category-option " + (o.value === values.category ? 'selected' : '')} key={o.value}>
                                                                <img width="80px" src={DeviceImages.formatSrc(DeviceImages.getImageSvg(constants.device.colors.online, null, false, 0, o.value))} />
                                                            </div>
                                                        )
                                                    })}
                                                </div>*/}
                                                        </FormGroup>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <div>
                                                        <FormGroup>
                                                            <FormLabel>{t("sharedPhone")}</FormLabel>
                                                            <FormControl
                                                                type="text"
                                                                name="phone"
                                                                defaultValue={values.phone}
                                                                onChange={handleChange}
                                                                isInvalid={!!errors.phone}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <div>
                                                        <FormGroup>
                                                            <FormLabel>{t("deviceContact")}</FormLabel>
                                                            <FormControl
                                                                type="text"
                                                                name="contact"
                                                                defaultValue={values.contact}
                                                                onChange={handleChange}
                                                                isInvalid={!!errors.contact}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <div>
                                                        <FormGroup>
                                                            <FormLabel>{t("deviceIdentifier")}</FormLabel>
                                                            <FormControl
                                                                type="text"
                                                                name="uniqueId"
                                                                defaultValue={values.uniqueId}
                                                                onChange={handleChange}
                                                                isInvalid={!!errors.uniqueId}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <div>
                                                        <FormGroup>
                                                            <FormLabel>{t("groupParent")}</FormLabel>
                                                            <CustomSelect options={groupOptions} name="groupId"
                                                                          isClearable
                                                                          defaultValue={values.groupId}
                                                                          onChange={setFieldValue}/>
                                                        </FormGroup>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <div>
                                                        <FormGroup>
                                                            <FormLabel>{"Brand"}</FormLabel>
                                                            <FormControl
                                                                type="text"
                                                                name="model"
                                                                defaultValue={values.brand}
                                                                onChange={( event) => {
                                                                    const item = this.state.item
                                                                    item.attributes["brand"] = event.target.value
                                                                    this.setState({
                                                                        item: item, changed: true
                                                                    })
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <div>
                                                        <FormGroup>
                                                            <FormLabel>{t("deviceModel")}</FormLabel>
                                                            <FormControl
                                                                type="text"
                                                                name="model"
                                                                defaultValue={values.model}
                                                                onChange={handleChange}
                                                                isInvalid={!!errors.model}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <div>
                                                        <FormGroup>
                                                            <FormLabel>{t("manualSelectDriver")}</FormLabel>
                                                            <CustomSelect
                                                                options={driverOptions} name="driver" isClearable
                                                                defaultValue={values.attributes["currentDriver"]}
                                                                onChange={(name, value) => {
                                                                    const item = this.state.item
                                                                    item.attributes["currentDriver"] = value
                                                                    this.setState({
                                                                        item: item, changed: true
                                                                    })
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            <div className="mt-1">
                                                <Collapse
                                                    title={<h5
                                                        className="m-0">{t('Maintenance')}</h5>}>
                                                    <FormGroup>
                                                        {/*<FormLabel>{t("Next Maintenance")}</FormLabel>*/}

                                                        <div className='w-50'>
                                                            <FormLabel>{t("sharedName")}</FormLabel>
                                                        </div>
                                                        <div>
                                                            <FormControl
                                                                type="text"
                                                                name="name"
                                                                defaultValue={maintenanceName}
                                                                onChange={event => {
                                                                    let m = this.state.deviceMaintenance
                                                                    if (m === null)
                                                                        m = {
                                                                            type: null,
                                                                            name: null,
                                                                            attributes: {}
                                                                        }
                                                                    m.name = event.target.value
                                                                    this.setState({
                                                                        deviceMaintenance: m,
                                                                        changed: true
                                                                    })
                                                                }}
                                                            />
                                                        </div>

                                                        <FormGroup className="mt-4">
                                                            <CustomCheckbox name={'date'}
                                                                            value={true}
                                                                            defaultChecked={maintenanceDateSelected}
                                                                            checked={maintenanceDateSelected}
                                                                            onChange={event => {
                                                                                this.setState({
                                                                                    maintenanceDateSelected: event.target.checked,
                                                                                    changed: true
                                                                                })
                                                                            }}
                                                                            label={t('date')}/>
                                                        </FormGroup>

                                                        {!maintenanceDateSelected ? null
                                                            :
                                                            <FormGroup>
                                                                <Row className="w-100 ml-2">
                                                                    <FormGroup>
                                                                        <Datetime timeFormat={"HH:mm"}
                                                                                  dateFormat="YYYY-MM-DD"
                                                                                  defaultValue={maintenanceCurrentDate}
                                                                                  onChange={(date) => {
                                                                                      let m = this.state.deviceMaintenance
                                                                                      if (m === null)
                                                                                          m = {
                                                                                              type: null,
                                                                                              name: null,
                                                                                              attributes: {}
                                                                                          }
                                                                                      m.attributes["date"] = date.valueOf()
                                                                                      this.setState({
                                                                                          deviceMaintenance: m,
                                                                                          changed: true
                                                                                      })
                                                                                  }}> </Datetime>
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <div className='ml-3'>
                                                                            <CustomCheckbox name={'repeat'}
                                                                                            value={true}
                                                                                            defaultChecked={repeatableSelected}
                                                                                            onChange={event => {
                                                                                                let m = this.state.deviceMaintenance
                                                                                                if (m === null)
                                                                                                    m = {
                                                                                                        type: null,
                                                                                                        name: null,
                                                                                                        attributes: {}
                                                                                                    }
                                                                                                m.attributes["repeatable"] = event.target.checked;
                                                                                                this.setState({
                                                                                                    deviceMaintenance: m,
                                                                                                    changed: true
                                                                                                })
                                                                                            }}
                                                                                            label={t('repeatable')}/>
                                                                        </div>
                                                                    </FormGroup>
                                                                </Row>
                                                            </FormGroup>
                                                        }

                                                        {!repeatableSelected ?
                                                            null : !maintenanceDateSelected ? null : <FormGroup>
                                                                <div className='mt-1'>
                                                                    <Row className="ml-2">

                                                                        <FormLabel>{t("Every")}</FormLabel>

                                                                        <div className='ml-3'>
                                                                            <FormGroup>
                                                                                <CustomNumberField
                                                                                    xdatatype={"days"}
                                                                                    name="Repeat Unit"
                                                                                    value={currentRepeatCount}
                                                                                    onChange={event => {
                                                                                        let m = this.state.deviceMaintenance
                                                                                        if (m === null)
                                                                                            m = {
                                                                                                type: null,
                                                                                                name: null,
                                                                                                attributes: {}
                                                                                            }
                                                                                        m.attributes["repeatDayCount"] = event.target.value;
                                                                                        this.setState({
                                                                                            deviceMaintenance: m,
                                                                                            changed: true
                                                                                        })
                                                                                    }
                                                                                    }/>
                                                                            </FormGroup>
                                                                        </div>
                                                                        <div className='ml-3'>
                                                                            <FormGroup>
                                                                                <FormControl
                                                                                    as="select"
                                                                                    name="repeatType"
                                                                                    defaultValue={maintenanceRepeatType}
                                                                                    onChange={event => {
                                                                                        let m = this.state.deviceMaintenance
                                                                                        if (m === null)
                                                                                            m = {
                                                                                                type: null,
                                                                                                name: null,
                                                                                                attributes: {}
                                                                                            }
                                                                                        m.attributes["repeatType"] = event.target.value;
                                                                                        this.setState({
                                                                                            deviceMaintenance: m,
                                                                                            changed: true
                                                                                        })
                                                                                    }
                                                                                    }>
                                                                                    {maintenanceOptions.map(o => {
                                                                                        return <option key={o.name}
                                                                                                       value={o.value}>{o.name}</option>
                                                                                    })}
                                                                                </FormControl>
                                                                            </FormGroup>
                                                                        </div>

                                                                    </Row>
                                                                </div>
                                                            </FormGroup>
                                                        }

                                                        <hr/>
                                                        <FormGroup className="mt-2">
                                                            <CustomCheckbox name={'odometer'}
                                                                            defaultChecked={maintenancePeriodSelected}
                                                                            checked={maintenancePeriodSelected}
                                                                            onChange={event => {
                                                                                this.setState({
                                                                                    maintenancePeriodSelected: event.target.checked
                                                                                })
                                                                            }}
                                                                            label={t('odometer')}/>
                                                        </FormGroup>

                                                        {!maintenancePeriodSelected ? null :
                                                            <>
                                                                <FormGroup>
                                                                    <FormLabel>{t("maintenanceStart")}</FormLabel>
                                                                    <CustomNumberField
                                                                        type="number"
                                                                        name="start"
                                                                        xdatatype={'distance'}
                                                                        value={this.state.deviceMaintenance ? this.state.deviceMaintenance.attributes["maintenanceStart"] ?? 0 : 0}
                                                                        onChange={event => {
                                                                            let m = this.state.deviceMaintenance
                                                                            if (m === null)
                                                                                m = {
                                                                                    type: null,
                                                                                    name: null,
                                                                                    attributes: {}
                                                                                }
                                                                            m.attributes["maintenanceStart"] = event.target.value
                                                                            this.setState({
                                                                                deviceMaintenance: m,
                                                                                changed: true
                                                                            })
                                                                        }}
                                                                    />
                                                                </FormGroup>
                                                                <FormGroup>
                                                                    <FormLabel>{t("maintenancePeriod")}</FormLabel>
                                                                    <CustomNumberField
                                                                        type="number"
                                                                        name="period"
                                                                        xdatatype={'distance'}
                                                                        value={this.state.deviceMaintenance ? this.state.deviceMaintenance.attributes["maintenancePeriod"] ?? 0 : 0}
                                                                        onChange={event => {
                                                                            let m = this.state.deviceMaintenance
                                                                            if (m === null)
                                                                                m = {
                                                                                    type: null,
                                                                                    name: null,
                                                                                    attributes: {}
                                                                                }
                                                                            m.attributes["maintenancePeriod"] = event.target.value
                                                                            this.setState({
                                                                                deviceMaintenance: m,
                                                                                changed: true
                                                                            })
                                                                        }}
                                                                    />
                                                                </FormGroup>
                                                            </>
                                                        }
                                                    </FormGroup>


                                                </Collapse>
                                            </div>
                                            <div className="mt-4">
                                                <Collapse
                                                    title={<h5
                                                        className="m-0">{t('Tech Inspection & Insurance')}</h5>}>
                                                    <FormGroup>
                                                        <FormLabel>{t("technicalInspection")}</FormLabel>

                                                        <FormGroup>
                                                            <div>
                                                                <Form inline>
                                                                    <FormGroup>
                                                                        <Datetime timeFormat={"HH:mm"}
                                                                                  dateFormat="YYYY-MM-DD"
                                                                                  defaultValue={techInspectionCurrentDate}
                                                                                  onChange={(date) => {
                                                                                      this.state.item._data.attributes["techInspectionDate"] = date.valueOf()
                                                                                      this.setState({
                                                                                          changed: true
                                                                                      })
                                                                                  }}> </Datetime>
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <div className='ml-3'>
                                                                            <CustomCheckbox name={'repeat'}
                                                                                            value={true}
                                                                                            defaultChecked={techInspectionRepeatableSelected}
                                                                                            checked={techInspectionRepeatableSelected}
                                                                                            onChange={event => {
                                                                                                this.state.item._data.attributes["techInspectionRepeatable"] = event.target.checked;
                                                                                                this.setState({
                                                                                                    changed: true
                                                                                                })

                                                                                            }}
                                                                                            label={t('repeatable')}/>
                                                                        </div>
                                                                    </FormGroup>
                                                                    <div className='ml-3'>
                                                                        <FormLabel>{t("sharedName")}</FormLabel>
                                                                    </div>
                                                                    <div className='ml-1'>
                                                                        <FormControl
                                                                            type="text"
                                                                            name="name"
                                                                            defaultValue={techInspectionName}
                                                                            onChange={event => {
                                                                                this.state.item._data.attributes["techInspectionName"] = event.target.value;
                                                                                this.setState({
                                                                                    changed: true
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </Form>
                                                            </div>
                                                        </FormGroup>

                                                        {!techInspectionRepeatableSelected ?
                                                            null : <FormGroup>
                                                                <div className='mt-1'>
                                                                     <Form inline>

                                                                        <FormLabel>{t("Every")}</FormLabel>

                                                                        <div className='ml-3'>
                                                                            <FormGroup>
                                                                                <CustomNumberField
                                                                                    xdatatype={"days"}
                                                                                    name="techInspectionRepeatDayCount"
                                                                                    value={techInspectionCurrentRepeatCount}
                                                                                    onChange={event => {
                                                                                        this.state.item._data.attributes["techInspectionRepeatDayCount"] = event.target.value;
                                                                                        this.setState({
                                                                                            changed: true
                                                                                        })
                                                                                    }
                                                                                    }/>
                                                                            </FormGroup>
                                                                        </div>
                                                                        <div className='ml-3'>
                                                                            <FormGroup>
                                                                                <FormControl
                                                                                    as="select"
                                                                                    name="repeatType"
                                                                                    defaultValue={techInspectionRepeatType}
                                                                                    onChange={event => {
                                                                                        this.state.item._data.attributes["techInspectionRepeatType"] = event.target.value;
                                                                                        this.setState({
                                                                                            changed:true
                                                                                        })
                                                                                    }
                                                                                    }>
                                                                                    {maintenanceOptions.map(o => {
                                                                                        return <option key={o.name}
                                                                                                       value={o.value}>{o.name}</option>
                                                                                    })}
                                                                                </FormControl>
                                                                            </FormGroup>
                                                                        </div>

                                                                    </Form>
                                                                </div>
                                                            </FormGroup>
                                                        }
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <FormLabel>{t("insurance")}</FormLabel>

                                                        <FormGroup>
                                                            <div>
                                                                <Form inline>
                                                                    <FormGroup>
                                                                        <Datetime timeFormat={"HH:mm"}
                                                                                  dateFormat="YYYY-MM-DD"
                                                                                  defaultValue={techInspectionCurrentDate}
                                                                                  onChange={(date) => {
                                                                                      this.state.item._data.attributes["insuranceDate"] = date.valueOf()
                                                                                      this.setState({changed:true})
                                                                                  }}> </Datetime>
                                                                    </FormGroup>

                                                                    <FormGroup>
                                                                        <div className='ml-3'>
                                                                            <CustomCheckbox name={'repeat'}
                                                                                            value={true}
                                                                                            defaultChecked={insuranceRepeatableSelected}
                                                                                            checked={insuranceRepeatableSelected}
                                                                                            onChange={event => {
                                                                                                this.state.item._data.attributes["insuranceRepeatable"] = event.target.checked;
                                                                                                this.setState({changed:true})
                                                                                            }}
                                                                                            label={t('repeatable')}/>
                                                                        </div>
                                                                    </FormGroup>

                                                                    <div className='ml-3'>
                                                                        <FormLabel>{t("sharedName")}</FormLabel>
                                                                    </div>
                                                                    <div className='ml-1'>
                                                                        <FormControl
                                                                            type="text"
                                                                            name="name"
                                                                            defaultValue={insuranceName}
                                                                            onChange={event => {
                                                                                this.state.item._data.attributes["insuranceName"] = event.target.value;
                                                                                this.setState({changed:true})
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </Form>
                                                            </div>
                                                        </FormGroup>

                                                        {!insuranceRepeatableSelected ?
                                                            null : <FormGroup>
                                                                <div className='mt-1'>
                                                                    <Form inline>

                                                                        <FormLabel>{t("Every")}</FormLabel>

                                                                        <div className='ml-3'>
                                                                            <FormGroup>
                                                                                <CustomNumberField
                                                                                    xdatatype={"days"}
                                                                                    name="Repeat Unit"
                                                                                    value={insuranceCurrentRepeatCount}
                                                                                    onChange={event => {
                                                                                        this.state.item._data.attributes["insuranceRepeatDayCount"] = event.target.value;
                                                                                        this.setState({changed:true})
                                                                                    }
                                                                                    }/>
                                                                            </FormGroup>
                                                                        </div>
                                                                        <div className='ml-3'>
                                                                            <FormGroup>
                                                                                <FormControl
                                                                                    as="select"
                                                                                    name="repeatType"
                                                                                    defaultValue={insuranceRepeatType}
                                                                                    onChange={event => {
                                                                                        this.state.item._data.attributes["insuranceRepeatType"] = event.target.value;
                                                                                        this.setState({changed:true})
                                                                                    }
                                                                                    }>
                                                                                    {maintenanceOptions.map(o => {
                                                                                        return <option key={o.name}
                                                                                                       value={o.value}>{o.name}</option>
                                                                                    })}
                                                                                </FormControl>
                                                                            </FormGroup>
                                                                        </div>

                                                                    </Form>
                                                                </div>
                                                            </FormGroup>
                                                        }
                                                    </FormGroup>
                                                </Collapse>
                                            </div>
                                            {/*<FormGroup>*/
                                            }
                                            {/*    <FormLabel>{t("attributeDevicePassword")}</FormLabel>*/
                                            }
                                            {/*    <FormControl*/
                                            }
                                            {/*        type="text"*/
                                            }
                                            {/*        name="password"*/
                                            }
                                            {/*        defaultValue={values.attributes["devicePassword"]}*/
                                            }
                                            {/*        onChange={event => {*/
                                            }
                                            {/*            this.state.item.attributes["devicePassword"] = event.target.value*/
                                            }
                                            {/*            handleChange(event)*/
                                            }
                                            {/*        }}*/
                                            }
                                            {/*    />*/
                                            }
                                            {/*</FormGroup>*/
                                            }
                                            <Grid className="mt-4" container rowSpacing={1}
                                                  columnSpacing={{xs: 1, sm: 2, md: 3}}>
                                                <Grid item xs={6}>
                                                    <FormGroup>
                                                        <FormLabel>{t("attributeWebReportColor")}</FormLabel>
                                                        <CustomColorField
                                                            name="color"
                                                            value={values.attributes["web.reportColor"]}
                                                            onChange={
                                                                event => {
                                                                    this.state.item.attributes["web.reportColor"] = event
                                                                    this.setState({
                                                                        item: this.state.item,
                                                                        changed: true
                                                                    })
                                                                    handleChange(event)
                                                                }
                                                            }
                                                        />
                                                    </FormGroup>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormGroup>
                                                        <FormLabel>{t("sharedTimezone")}</FormLabel>
                                                        <FormControl
                                                            as="select"
                                                            name="timezone"
                                                            defaultValue={values.attributes["decoder.timezone"]}
                                                            onChange={event => {
                                                                this.state.item.attributes["decoder.timezone"] = event.target.value
                                                                handleChange(event)
                                                            }
                                                            }>
                                                            <option value={""}></option>
                                                            {Object.values(store.getState().timezones.timezones).sort((a, b) => a.key > b.key ? 1 : -1).map(o => {
                                                                return <option key={o.key}
                                                                               value={o.key}>{t(o.key)}</option>
                                                            })}
                                                        </FormControl>
                                                    </FormGroup>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormGroup>
                                                        <FormLabel>{t("attributeSpeedLimit")}</FormLabel>
                                                        <CustomNumberField
                                                            name="speed"
                                                            value={values.attributes.speedLimit}
                                                            type="number"
                                                            xdatatype={'speed'}
                                                            onChange={event => {
                                                                if (event.target.value > 0)
                                                                    this.state.item.attributes["speedLimit"] = event.target.value
                                                                else
                                                                    delete this.state.item.attributes["speedLimit"]
                                                                handleChange(event)
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </Grid>
                                                {App.App.user.administrator ?
                                                    <Grid item xs={6}>
                                                        <FormGroup>
                                                            <FormLabel>{t("attributeReportIgnoreOdometer")}</FormLabel>
                                                            <CustomCheckbox name={'odometer'}
                                                                            value={true}
                                                                            defaultChecked={values.attributes["report.ignoreOdometer"]}
                                                                            checked={values.attributes["report.ignoreOdometer"]}
                                                                            onChange={event => {
                                                                                this.state.item.attributes["report.ignoreOdometer"] = event.target.checked
                                                                                handleChange(event)
                                                                            }}
                                                                            label={t('commandEnable')}/>
                                                        </FormGroup>
                                                    </Grid>
                                                    : null
                                                }

                                                {
                                                    App.App.user.administrator ?
                                                        <Grid item xs={6}>
                                                            <FormGroup className="mb-3">
                                                                <CustomCheckbox name="disabled"
                                                                                defaultChecked={values.disabled}
                                                                                value={true} onChange={handleChange}
                                                                                label={t('sharedDisabled')}/>
                                                            </FormGroup>
                                                        </Grid>
                                                        : null
                                                }
                                                <Grid item xs={6}>
                                                    <FormGroup>
                                                        <FormLabel>{t("positionOdometer")}</FormLabel>
                                                        <CustomNumberField xdatatype='distance' name="Odometer"
                                                                           value={position && position.attributes["odometer"] ? position.attributes["odometer"] : null}
                                                                           onChange={event => {
                                                                               // this.state.odometer = event.target.value
                                                                               // this.state.changed = true
                                                                               this.setState({
                                                                                   odometer: event.target.value,
                                                                                   changed: true
                                                                               })
                                                                           }
                                                                           }/>
                                                    </FormGroup>
                                                </Grid>
                                            </Grid>
                                            {
                                                position && App.App.user.administrator ?
                                                    <div className="mt-3">
                                                        <Collapse
                                                            title={<h5
                                                                className="m-0">{t('sharedDeviceAccumulators')}</h5>}>
                                                            <div>
                                                                <FormGroup>
                                                                    <FormLabel>{t("deviceTotalDistance")}</FormLabel>
                                                                    <CustomNumberField xdatatype='distance'
                                                                                       name="totalDistance"
                                                                                       value={values.totalDistance}
                                                                                       onChange={handleChange}/>
                                                                </FormGroup>
                                                                <FormGroup>
                                                                    <FormLabel>{t("positionHours")}</FormLabel>
                                                                    <CustomNumberField xdatatype='hours'
                                                                                       name="hours"
                                                                                       value={values.hours}
                                                                                       onChange={handleChange}/>
                                                                </FormGroup>
                                                                <FormGroup>
                                                                    <Button type="button" variant="primary"
                                                                            onClick={() => this.saveAccumulators(values)}>
                                                                        <i className="mdi mdi-content-save"></i> {t('sharedSave')}
                                                                    </Button>
                                                                </FormGroup>
                                                            </div>
                                                        </Collapse>
                                                    </div> : null
                                            }
                                        </div>
                                    </div>
                                    <footer className="footer">
                                        <div className="container d-flex">
                                            <div className="flex-grow-1 d-flex">
                                                {
                                                    App.App.user.administrator ?
                                                        <Button variant="primary" className="align-self-stretch"
                                                                onClick={() => this.openAttributesPage()}>
                                                            <i className="mdi mdi-playlist-play"></i> {t('sharedAttributes')}
                                                        </Button>
                                                        : null
                                                }
                                            </div>
                                            <Button variant="contained" size="large" className="btn-red mr-2"
                                                    onClick={() => this.cancel()}>
                                                <i className="mdi mdi-close"></i> {t('sharedCancel')}
                                            </Button>
                                            <Button type="submit" variant="contained"  size="large" className="btn-blue"
                                                    disabled={isSubmitting || !this.state.changed && (!dirty || !isValid)}>
                                                <i className="mdi mdi-content-save"></i> {t('sharedSave')}
                                            </Button>
                                        </div>
                                    </footer>
                                </React.Fragment>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </React.Fragment>
    }
}